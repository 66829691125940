import React from "react";
import {RouteHandler} from "./RouteHandler";

function App() {

    return (
        <>
            <RouteHandler/>
        </>
    );
}

export {App};