import {About} from "./About";
import {Home} from "./Home";
import {Settings} from "./Settings";

const routes = [
    Home,
    About,
    Settings,
];

export {routes};